// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-brides-js": () => import("./../src/pages/brides.js" /* webpackChunkName: "component---src-pages-brides-js" */),
  "component---src-pages-contact-removed-js": () => import("./../src/pages/contact_removed.js" /* webpackChunkName: "component---src-pages-contact-removed-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-testimonials-js": () => import("./../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-templates-single-bride-js": () => import("./../src/templates/single-bride.js" /* webpackChunkName: "component---src-templates-single-bride-js" */)
}

